<template>
  <v-simple-table>
    <template v-slot:default>
      <tbody>
        <tr v-for="(item, key) in userData" :key="key">
          <th>{{ item.title }}</th>
          <td>{{ item.value || "-" }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
    userData() {
      const data = this.user;

      return {
        email: { value: data.email, title: "E-mail" }
      };
    }
  }
};
</script>

<style>
</style>
