<template>
  <!-- Menu -->
  <v-row>
    <v-col cols="12" class="" v-if="filteredMenuItems.length">
      <!-- Items do Menu -->
      <v-list class="mb-4 py-0">
        <v-list-item
          v-for="(item, key) in filteredMenuItems"
          :key="key"
          @click="item.click"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <!-- Dialogs -->
      <DialogPassword ref="password_dialog" />
    </v-col>
  </v-row>
</template>

<script>
// Dialogs
import DialogPassword from "@/views/People/_shared/DialogPassword";
// Libraries
import { intersect } from "@/plugins/utils";
// Components
import KAvatar from "@/components/KAvatar.vue";

export default {
  props: {
    profile: Object
  },

  components: {
    // Dialogs
    DialogPassword,
    // components
    KAvatar
  },

  data() {
    return {
      menuItems: [
        {
          title: "Alterar Senha",
          description: "Alterar minha senha",
          icon: "mdi-account-key",
          click: () => this.openDialogPassword(),
          roles: ["logged"]
        }
      ]
    };
  },

  computed: {
    filteredMenuItems() {
      // Array com as permissões do usuário logado.
      const userRoles = [...this.$store.getters["auth/roles"]];

      // Verifica se pode alterar senha
      if (this.hasPassword) userRoles.push("has_password");

      // Filtra as opções do menu para exibir somente os itens
      // que possuem permissões que batam com as permissões do
      // usuário logado
      return this.menuItems.filter(item => {
        return intersect(userRoles, item.roles).length;
      });
    },

    // Verifica se precisa de coligação
    hasPassword() {
      return this.$store.getters["auth/user"].has_password;
    }
  },
  methods: {
    openDialogPassword() {
      this.$refs.password_dialog.open();
    }
  }
};
</script>

<style>
</style>
