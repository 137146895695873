<template>
  <KDialog
    max-widh="360"
    :title="title"
    :visible="visible"
    :loading="loading"
    :actions="dialogActions"
    @click:close="close"
    @click:save="save"
  >
    <KForm ref="form">
      <v-container>
        <v-row class="my-0">
          <v-col cols="12" class="d-flex pt-0 justify-center">
            <v-card
              outlined
              class="overflow-hidden"
              :style="{
                width: `${size ? size + 'px' : '100%'}`,
                hseight: `${size ? size + 'px' : '100%'}`,
              }"
            >
              <PickImage
                :width="size"
                :height="size"
                v-model="file"
                ref="pick_image"
              />
            </v-card>
            <ErrorLabel v-if="errors.file" :message="errors.file" />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12">
            <KAlert info
              >Imagens do tipo PNG ou JPG com tamanho mínimo de 256 x 256 pixels
              na proporção 1:1</KAlert
            >
          </v-col>
        </v-row>
      </v-container>
    </KForm>
  </KDialog>
</template>

<script>
import KForm from "@/components/KForm";
import KAlert from "@/components/KAlert";
import KDialog from "@/components/KDialog";
import PickImage from "@/components/Customize/PickImage";
import ErrorLabel from "@/components/ErrorLabel";

export default {
  components: {
    KForm,
    KAlert,
    KDialog,
    PickImage,
    ErrorLabel
  },

  data() {
    return {
      // Dados
      data: {},
      errors: {},
      file: null,
      // Dialog
      title: "Alterar imagem de perfil",
      visible: false,
      loading: false,
      dialogActions: [{ label: "Enviar Imagem", eventName: "click:save" }]
    };
  },

  computed: {
    /**
     * Calcula o tamanho que deve ser usado para o campo da
     * imagem, evitando que fique muito pequeno ou muito grande de
     * acordo com o tamanho da tela.
     */
    size() {
      return this.$vuetify.breakpoint.xsOnly ? null : 400;
    }
  },

  methods: {
    /*******************************************************
     *
     *  FUNÇÕES PARA SALVAR E EDITAR OS DADOS NO STORE
     *
     *******************************************************/

    /**
     * Salva os dados de um novo usuário no Store
     */
    async save(editing) {
      try {
        const file = await this.$refs["pick_image"].getBlob();

        this.loading = true;

        const upload = await this.$store.dispatch("auth/updateAvatar", file);

        if (upload) {
          this.$message.success("Imagem alterada com sucesso");
          this.$emit("save");
          this.close();
        } else {
          this.$message.error("Erro ao carregar a imagem");
        }

        this.loading = false;
      } catch (error) {
        console.log("erro no uppllaod", error);
        this.$message.error("Erro ao alterar a imagem do usuário");
        this.loading = false;
      }
    },

    /**
     * Abre o dialog preparado para alteerar os dados
     */
    openToUpload(avatar_url) {
      console.log("dentro do dialgo", avatar_url);
      this.file = avatar_url;

      // Abre por último, depois que está tudo preenchido
      this.open();
    },

    /**
     * Abre o dialog
     */
    async open() {
      this.visible = true;
    },

    /**
     * Fecha o dialog
     */
    close() {
      this.visible = false;
      this.clear();
    },

    /**
     * Limpa os dados do dialog
     */
    clear() {
      this.data = {};
      this.file = null;
    }
  }
};
</script>

<style scoped>
</style>
