var render = function render(){var _vm=this,_c=_vm._self._c;return _c('KDialog',{attrs:{"max-width":"360","visible":_vm.visible,"loading":_vm.loading,"title":_vm.title,"actions":_vm.dialogActions},on:{"click:close":_vm.close,"click:save":_vm.save}},[_c('k-form',{ref:"form"},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12"}},[_c('v-password',{attrs:{"outlined":"","dense":"","label":"Senha Atual","error-messages":_vm.errors.current_password,"rules":[
              _vm.$validation.hasMinLength(
                _vm.data.current_password,
                6,
                'Mínimo de 6 caracteres'
              ),
              _vm.$validation.isRequired,
            ]},model:{value:(_vm.data.current_password),callback:function ($$v) {_vm.$set(_vm.data, "current_password", $$v)},expression:"data.current_password"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12"}},[_c('v-password',{attrs:{"outlined":"","dense":"","label":"Nova Senha","error-messages":_vm.errors.password,"rules":[
              _vm.$validation.hasMinLength(
                _vm.data.password,
                6,
                'Mínimo de 6 caracteres'
              ),
              _vm.$validation.isRequired,
            ]},model:{value:(_vm.data.password),callback:function ($$v) {_vm.$set(_vm.data, "password", $$v)},expression:"data.password"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12"}},[_c('v-password',{attrs:{"outlined":"","dense":"","label":"Confirmação de Senha","error-messages":_vm.errors.password_confirmation,"rules":[
              _vm.passwordEquals,
              _vm.$validation.hasMinLength(
                _vm.data.password_confirmation,
                6,
                'Mínimo de 6 caracteres'
              ),
              _vm.$validation.isRequired,
            ]},model:{value:(_vm.data.password_confirmation),callback:function ($$v) {_vm.$set(_vm.data, "password_confirmation", $$v)},expression:"data.password_confirmation"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }