<template>
  <k-page>
    <v-row class="ma-0" v-once>
      <v-col cols="12" sm="12" align="center" justify="center">
        <Header :profile="profile" />
      </v-col>

      <!-- Menu -->
      <v-col cols="12" md="6" v-once>
        <v-row class="ma-0">
          <v-col cols="12">
            <SectionHeader title="Menu" />
          </v-col>
          <v-col cols="12">
            <Menu :profile="profile" />
          </v-col>
        </v-row>
      </v-col>

      <!-- Dados -->
      <v-col cols="12" md="6" v-once>
        <v-row class="ma-0">
          <v-col cols="12">
            <SectionHeader title="Dados" />
          </v-col>
          <v-col cols="12">
            <Info :profile="profile" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </k-page>
</template>


<script>
// Componentes
import KPage from "@/components/KPage";
import KAvatar from "@/components/KAvatar";
import SectionHeader from "@/components/SectionHeader";

// Partes
import Header from "./Header";
import Menu from "./Menu";
import Info from "./Info";

export default {
  components: {
    // Components básicos
    KPage,
    KAvatar,
    SectionHeader,
    // Partes
    Header,
    Menu,
    Info
  },

  //
  data() {
    return {
      loading: false
    };
  },

  //
  computed: {
    profile() {
      return this.$store.getters["auth/user"];
    }
  },

  //
  methods: {
    //
    async loadData() {
      this.loading = true;
      this.$loading.show();

      // Pega os dados da coligação caso seja um candidato
      await Promise.all([this.$store.dispatch("auth/profile")]);

      this.loading = false;

      this.$loading.hide();
    }
  },

  //
  created() {
    this.loadData();
  }
};
</script>

<style>
</style>
