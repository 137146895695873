<template>
  <div class="wrapper">
    <v-row>
      <v-responsive
        style="overflow: unset"
        :aspect-ratio="3 / 1"
        width="100%"
        class="neutral"
      >
        <!-- Imagem de capa -->
        <k-image
          width="100%"
          :aspect-ratio="3 / 1"
          align="center"
          :src="cover_url"
        ></k-image>

        <!-- Avatar -->
        <div
          class="avatar centered grey lighten-2"
          :class="{ mobile: $vuetify.breakpoint.smAndDown }"
          style="background-size: cover; overflow: hidden"
          :stsyle="{ backgroundImage: `url(${avatar_url})` }"
        >
          <k-image
            :width="$vuetify.breakpoint.smAndDown ? 140 : 256"
            :aspect-ratio="1"
            align="center"
            :src="avatar_url"
          ></k-image>
        </div>

        <!-- Botão de edição da imaem de capa -->
        <!-- <div class="cover-button">
          <v-tooltip v-model="state.cameraCover" bottom color="rgba(0,0,0,.9)">
            <template v-slot:activator="{ on }">
              <v-btn
                class="v-btn--contained"
                :icon="$vuetify.breakpoint.xsOnly"
                v-on="on"
                @click="openDialog"
              >
                <v-icon>mdi-camera</v-icon>
                <span v-if="$vuetify.breakpoint.smAndUp">Capa</span>
              </v-btn>
            </template>
            <span>Clique para editar a imagem de capa</span>
          </v-tooltip>
        </div> -->

        <!-- Botão de edição do avatar -->
        <div class="avatar-button neutral">
          <v-tooltip v-model="state.cameraAvatar" bottom color="rgba(0,0,0,.9)">
            <template v-slot:activator="{ on }">
              <v-btn
                class="v-btn--contained"
                icon
                v-on="on"
                @click="openAvatarDialog"
              >
                <v-icon>mdi-camera</v-icon>
              </v-btn>
            </template>
            <span>Clique para editar a imagem do avatar</span>
          </v-tooltip>
        </div>
      </v-responsive>

      <!-- Dados do Usuário -->
      <v-col style="min-height: 90px">
        <!-- Nome -->
        <h1 class="display-1 font-weight-light mt-3 mb-0">
          {{ profile.profile && profile.profile.name }}
        </h1>
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <DialogAvatar ref="avatar_dialog" />
    <!-- <Dialog ref="cover_dialog" /> -->
  </div>
</template>

<script>
import KAvatar from "@/components/KAvatar";
import KImage from "@/components/KImage";
// import Dialog from "@/views/Profile/Dialog";
import DialogAvatar from "@/views/Profile/DialogAvatar";
export default {
  components: {
    KAvatar,
    KImage,
    // Dialog,
    DialogAvatar
  },

  props: {
    profile: Object
  },

  data: () => ({
    state: {
      tip: false // Mostra a dica
    },
    // avatar_url: "",
    cover_url: ""
  }),

  computed: {
    avatar_url() {
      const avatar = this.$store.getters["auth/user"].avatar;
      return (avatar && avatar.url) || "";
    }
  },

  methods: {
    openAvatarDialog() {
      this.$refs.avatar_dialog.openToUpload(this.avatar_url);
    },

    openDialog() {
      // this.$refs.cover_dialog.openToUpload(this.profile);
    }
  }
};
</script>

<style scoped>
.wrapper {
  position: relative;
}

/* Botão de ajuda */
.button-tip {
  position: absolute;
  top: 0;
  right: 0;
}

/* 
  Edição de imagem do avatar
 */
.avatar {
  position: absolute;
  bottom: 0;
  left: 60px;
  border: 3px solid white;
  border-radius: 50%;
  width: 256px;
  height: 256px;
  /*  */
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.3);
}

.avatar.centered {
  width: 256px;
  height: 256px;
  margin-left: calc(256px / -2);
  bottom: -20px;
  z-index: 1;
  left: 50%;
}

.avatar.centered.mobile {
  width: 140px;
  height: 140px;
  margin-left: calc(140px / -2);
}

.avatar-button {
  position: absolute;
  left: calc(50% + 30px);
  z-index: 2;
  border-radius: 50%;
  /* background: white; */
  bottom: -20px;
}

.cover-button {
  position: absolute;
  left: 10px;
  z-index: 2;
  border-radius: 50%;
  background: white;
  top: 10px;
}
</style>
