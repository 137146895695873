<template>
  <KDialog
    max-width="360"
    :visible="visible"
    :loading="loading"
    :title="title"
    @click:close="close"
    @click:save="save"
    :actions="dialogActions"
  >
    <k-form ref="form">
      <v-container>
        <v-row>
          <!-- Senha -->
          <v-col cols="12" sm="12" class="pb-0">
            <v-password
              outlined
              dense
              label="Senha Atual"
              :error-messages="errors.current_password"
              v-model="data.current_password"
              :rules="[
                $validation.hasMinLength(
                  data.current_password,
                  6,
                  'Mínimo de 6 caracteres'
                ),
                $validation.isRequired,
              ]"
            ></v-password>
          </v-col>
          <!-- Senha -->
          <v-col cols="12" sm="12" class="py-0">
            <v-password
              outlined
              dense
              label="Nova Senha"
              :error-messages="errors.password"
              v-model="data.password"
              :rules="[
                $validation.hasMinLength(
                  data.password,
                  6,
                  'Mínimo de 6 caracteres'
                ),
                $validation.isRequired,
              ]"
            ></v-password>
          </v-col>
          <!-- Confirmação de Senha -->
          <v-col cols="12" sm="12" class="py-0">
            <v-password
              outlined
              dense
              label="Confirmação de Senha"
              :error-messages="errors.password_confirmation"
              v-model="data.password_confirmation"
              :rules="[
                passwordEquals,
                $validation.hasMinLength(
                  data.password_confirmation,
                  6,
                  'Mínimo de 6 caracteres'
                ),
                $validation.isRequired,
              ]"
            ></v-password>
          </v-col>
        </v-row>
      </v-container>
    </k-form>
  </KDialog>
</template>

<script>
// Componentes
import KForm from "@/components/KForm";
import KDialog from "@/components/KDialog";
import VPassword from "@/components/VPassword";

//
export default {
  components: {
    KForm,
    KDialog,
    VPassword
  },

  data() {
    return {
      // Define se está visível
      visible: false,
      // Armazena os dados que virão via função para preencher
      data: {},
      loading: false,
      title: "Alterar Minha Senha",
      dialogActions: [
        {
          eventName: "click:save",
          label: "Alterar Senha"
        }
      ],

      errors: {}
    };
  },

  computed: {},

  methods: {
    /*******************************************************
     *
     *  FUNÇÕES PARA SALVAR E EDITAR OS DADOS NO STORE
     *
     *******************************************************/

    /**
     * Salva os dados de um novo usuário no Store
     */
    async save() {
      try {
        // this.clearServerErrors();
        this.errors = {};

        // Se as demais validações passaram
        if (this.$refs["form"].validate()) {
          this.loading = true;

          await this.$store.dispatch("auth/userChangePassword", this.data);

          this.$message.success("Dados salvos com sucesso");
          this.close();
        }
      } catch (error) {
        // this.data = {}
        this.errors = this.$message.serverError(error);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Senhas coincidem, caso seja uma adição de cadastro
     */
    passwordEquals() {
      if (
        String(this.data.password) !== String(this.data.password_confirmation)
      ) {
        return "As senhas não coincidem";
      }

      return true;
    },

    /*******************************************************
     *
     *  FUNÇÕES BÁSICAS DE QUALQUER DIALOG
     *
     *******************************************************/

    /**
     * Abre o dialog
     */
    open() {
      this.visible = true;
    },

    /**
     * Fecha o dialog
     */
    close() {
      this.visible = false;
      // Atrasando um pouco a limpeza dos dados para
      // não exibir os dados ficando apagados enquanto
      // fecho o dialog
      setTimeout(() => {
        this.clear();
        this.$refs.form.resetValidation();
      }, 100);
    },

    /**
     * Limpa os dados do dialog
     */
    clear() {
      this.data = {};
      this.errors = {};
    }
  }
};
</script>

<style>
</style>
